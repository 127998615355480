import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams, Link, useNavigate } from 'react-router-dom';
import DownloadsAPI from "Services/DownloadsAPI";
import useFileDownloader from "Hook/useFileDownloader";
import useIsReloaded from "Hook/useIsReloaded";
import Spinner from 'Components/Loading/Spinner';
import { useSelector } from 'react-redux';
//import axios from "axios";
import {convertSize} from "Services/utils";
import DefaultBox from 'assets/images/packshot/products/product_default.png';
//import {detectDevice} from "Services/utils";
//import { SliceText } from "Components/Tools/Text";
//<SliceText text={data.product.name} length={50}/>

//const device = detectDevice();

export default function DownloadList() {
    const navigate = useNavigate();
    const { t } = useTranslation();
    let params = useParams();
    document.title = t("download.title") + " – nanoways.com";

    const {isAuthenticated} = useSelector((state) => state.auth);
    
    const downloadBtn = useRef(null);
    const [downloadInfo, setDownloadInfo] = useState(null);
    const [logId, setLogId] = useState(null);

    const [counter, setCounter] = useState(0);
    const [error, setError] = useState(); //Download does not exist in DB OR File removed
    const [errorDownload, setErrorDownload] = useState(false); //Download file does not exist
    
    const [downloadFile, downloaderComponentUI, downloadEnd] = useFileDownloader();
    const [hideDownloadButton, setHideDownloadButton] = useState(false);

    //Check if user reload the page or come direct from Email
    const reloaded = useIsReloaded();
    /* eslint-disable */
    useEffect(() => {
        let isMounted = true;
        if(!downloadInfo && isMounted){
            DownloadsAPI.downloadsList(params.code)
            .then(response => {
                let data = response.data;
                if(data.file){
                    if(data.filesize > 0){
                        const convertedSize = convertSize(data.filesize);
                        data.filesizeConvert = convertedSize ? 
                        convertedSize.size +t(`gen.var.sep.unit`)+t(`gen.var.prefix`).charAt(convertedSize.pow)+t(`gen.var.byte`)
                        : t(`gen.text.unknown`);
                    }
                    setDownloadInfo(data);
                    //Automatic Download for big screen
                    /*if (device.type === "desktop" && !reloaded) {
                        downloadBtn.current.click();
                    }*/
                }else if(data.product){
                    if(reloaded){// download is purged & reload page => redirect to homepage
                        navigate(t("link.root.url"), {replace:true});
                    } else {
                        navigate(t("link.err.url"), {replace:true, state:{errGroup: "expire", option:data, docTitleI18n: "download.title"}});
                    }
                }
            })
            .catch(err => {
                console.log(err);
                console.log(err?.response?.data);
                if(!err.response || err.response.status === 500){
                    setError('500');
                }else {
                    const data = err.response.data;
                    //console.log(data.message);
                    if(data.message === undefined){
                        console.log(data);
                        setError('500');
                    } else {
                        let errMsg = data.message?.toLowerCase();
                        if(/^license|product/.test(errMsg)) {
                            navigate(t("link.err.url"), {replace:true, state:{errGroup: "noLK", docTitleI18n: "download.title"}});
                        } else if(errMsg === "download not found") {
                            navigate(t("link.err.url"), {replace:true, state:{errGroup: "noDL", docTitleI18n: "download.title"}});
                        } else if(errMsg === "download not allowed" || errMsg === "empty user data") {
                            navigate(t("link.err.url"), {replace:true, state:{errGroup: "forbid", docTitleI18n: "download.title"}});
                        } else if(errMsg === "expired") {//Expired License Key
                            navigate(t("link.err.url"), {state:{errGroup:'expired', info:data?.info, docTitleI18n: "download.title"}});
                        } else if(errMsg === "maxclear") {
                            navigate(t("link.err.url"), {state:{errGroup:'maxclear', docTitleI18n: "download.title"}});
                        } else if(errMsg === "norelease") {
                            navigate(t("link.err.url"), {state:{errGroup:data.i18nKey, info:data?.info, docTitleI18n: "download.title"}});
                        } else if(errMsg === "barred") {
                            navigate(t("link.err.url"), {state:{errGroup:data.i18nKey, info:data?.info, docTitleI18n: "download.title"}});
                        }else{
                            console.log(errMsg);
                            setError('500');
                        }
                    }
                }
                //User reload page F5 redirect to homepage
                if(reloaded){
                    navigate(t("link.root.url"), {replace:true});
                }
            });
        }
        //!location.state && navigate(t("link.lic.clear.lic"));

        return () => { isMounted = false }; // cleanup toggles value, if unmounted
    },[]);
    //Check every 5 mn if download exist
    useEffect(() => {
        let isMounted = true;
        const timer = setInterval(() => {
            if(downloadInfo?.downloadid && isMounted){
                DownloadsAPI.CheckPaketDone(downloadInfo.downloadid)
                .then(response => {
                    if(response.data?.purgedAt){
                        navigate(t("link.root.url"), {replace: true});
                    }
                })
            }
        }, 5000);
    
        return () => {
            isMounted = false;
            clearInterval(timer);
        };
    }, [downloadInfo]);

    useEffect(() => {
        setHideDownloadButton(false);
        const timer = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            }
        }, 1000);
    
        return () => {
            clearInterval(timer);
        };
    }, [counter]);

    useEffect(()=>{
        if (downloadEnd?.completed && logId) {
            setErrorDownload(false);
            DownloadsAPI.downloadEnd(
                {
                    logId: logId,
                    endTime: new Date().toISOString(),
                },
                isAuthenticated
            ).then(response => {
                console.log('Endtime Download log success');
                //console.log(response.data);
            })
            .catch(error => {
                console.log('Endtime download log failed');
                console.log(error);
            })
        }
        if (downloadEnd?.hasError && logId) {
            setErrorDownload(true);
            DownloadsAPI.downloadErr({logId}, isAuthenticated)
            .then(response => {
                console.log('Download send Error');
                //console.log(response.data);
            })
            .catch(error => {
                console.log('Errtime download log failed');
                console.log(error);
            })
        }
    }, [downloadEnd, logId])
    /* eslint-enable */

    const download = (e, downloadInfo) => {
		e.preventDefault();
        setErrorDownload(false);
        setHideDownloadButton(true);
        downloadFile(downloadInfo);
        startDownload();
        //Removed: response.headers['content-length'] doesn't work correctly bug by one User
        /*axios({
			method: 'HEAD',
			url: downloadInfo.file
        }).then(function(response) {
			const contentLength = response.headers['content-length'];
            console.log('HEADER: ', response.headers);
            console.log('HEADER-content-Length: ', contentLength);
			if (contentLength > 0) {
                setHideDownloadButton(true);
                downloadFile(downloadInfo);
                setErrorDownload(false);
                startDownload();
			} else {
                console.log('File is not available:');
                setCounter(10);
                setErrorDownload(true);
			}
        }).catch(function(error) {
			console.error('Error checking file availability', error);
            setCounter(10);
            setErrorDownload(true);
        });*/
    }

    const startDownload = () => {
        DownloadsAPI.downloadStart(
            {
                id: parseInt(downloadInfo.downloadid),
                filename: downloadInfo.filename,
                filesize: downloadInfo.filesize,
                startTime: new Date().toISOString(),
            }, 
            isAuthenticated
        ).then(res => {
            console.log('Starttime download log success');
            setLogId(res.data.logId);
        })
        .catch(error => {
            console.log('Starttime download log failed');
            console.log(error);
        })
    }

    return (
        <>
        {!downloadInfo && !error && <Spinner className={"overlay"}/>}
        <div className="m-0 p-0 vertical-center"> 
            <div className="bm-main">
                
                {(downloadInfo &&
                    <div className="bm-card bm-box-absolute">
                        <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                        <h1>{t("download.title")}</h1>
                        <h2 className="bm-sep-1">{downloadInfo.name}</h2>

                        <div className="row justify-content-center mb-5">
                            <div className="col-lg-8">
                                <div className="row mt-3">
                                    <div className="col">
                                        <div className="card nw-rounded-sm py-3  text-center">
                                            <div className="card-img-top">
                                                <img 
                                                    width="256"
                                                    height="256" 
                                                    src={downloadInfo.img} alt={downloadInfo.name}
                                                    onError={(e) => {
                                                        e.target.onerror = null;
                                                        e.target.src = process.env.PUBLIC_URL + DefaultBox;
                                                    }}
                                                />
                                            </div>
                                            <div className="card-body pt-0">
                                                <div title={t("clear."+downloadInfo.package)} className="fn-smtxt-bold nowrap mb-4">
                                                    {t("clear."+downloadInfo.package)}
                                                </div>
                                                {downloadInfo.license?.caption &&
                                                    <div title={downloadInfo.license.caption} className="nowrap">
                                                        {t("clear.select.info.cap")+downloadInfo.license.caption} 
                                                    </div>
                                                }
						{downloadInfo.version &&
						    <div title={downloadInfo.version} className="nowrap">
							{t("clear.select.info.version")+" "+downloadInfo.version+" ("+t(`gen.lang.${downloadInfo.language}`)+")"}
						    </div>
						}
                                                <div style={{height: "85px"}}>
                                                    {!hideDownloadButton && (
                                                    <>
                                                        <div className="fn-txt mb-4 nowrap">
                                                            {t(`clear.select.opt.size`) + " " +downloadInfo.filesizeConvert}
                                                        </div>
                                                        <button 
                                                            ref={downloadBtn}
                                                            disabled={(counter > 0 ) ? "disabled" : ""}
                                                            className={(counter > 0 ) ? "bm-btn-grey" : "bm-btn-blue"} 
                                                            onClick={(e) => download(e,downloadInfo)}
                                                        >
                                                            {(counter > 0) ? <span className="me-2">{t("download.btn.err")}</span> : t("download.btn")}
                                                            {(counter > 0 ) ?
                                                                <Spinner as="span" for="btn" overlayStyle={{verticalAlign: "top"}} iconStyle={{radius: "24px", borderColor: `${counter > 0 ? "#ffffff" : "var(--nw-blue-light-color)"}`}}/>
                                                            : ""}
                                                        </button>
                                                    </>
                                                    )}
                                                    {hideDownloadButton && downloaderComponentUI}
                                                </div>
                                            </div>
                                        </div>
                                        {downloadEnd?.completed && 
                                            <div className="nw-bg-green-dark alert alert-dismissible fade-in show px-box mt-2" role="alert">
                                                <div className="row p-0 align-items-center">
                                                    <p style={{fontSize: "1.5rem", marginBottom: "0.7rem"}} className="text-white" dangerouslySetInnerHTML={{__html:t("download.msg")}} />
                                                    <div className="col-12" dangerouslySetInnerHTML={{__html:t(downloadInfo.successI18n)}} />
                                                    {/*<button type="button" className="btn-close close-x btn-close-white" data-bs-dismiss="alert" aria-label="Close"></button>*/}
                                                </div>
                                            </div>
                                        }
                                        {(downloadEnd?.hasError || errorDownload) && 
                                            <div className="border nw-border-green alert alert-dismissible fade-in show px-box mt-2" role="alert">
                                                <div className="row p-0 align-items-center text-center">
                                                    <p style={{fontSize: "1.5rem", marginBottom: "0.7rem"}} className="nw-green-dark" dangerouslySetInnerHTML={{__html:t("download.msg.err")}} />
                                                    <div className="col-12" dangerouslySetInnerHTML={{__html:t("download.msg.err.txt", {directlink: downloadInfo.file})}} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>  
                        </div>
                    </div>
                )} 

                {error &&
                    <div className="bm-card bm-box-absolute">
                        <Link className="bm-close-x" to={t("link.root.url")}>&#10005;</Link>
                        <h1>{t("download.title")}</h1>
                        <h2 className="bm-sep-1 text-danger">{t("err.unexpected")}</h2>
                        <div className="row justify-content-center">
                            <div>{t("err.500")}</div>
                            <div className="mt-5">
                                <Link className="float-end" to={t("link.root.url")}>
                                    <button className="bm-btn-blue">{t("nw.btn.back")}</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
        </>
    );
}
